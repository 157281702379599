<template>
    <div class="mobileApprovalForm scrolling">
        <a-form :form="form" style="margin-top: 20px">
            <a-form-item label="处理人意见区">
                <a-radio-group @change="handleRadioChage" v-decorator="['spfs', { initialValue: '1' }]">
                    <a-radio value="1"> 同意 </a-radio>
                    <a-radio value="2" style="margin-left: 50px"> 驳回 </a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item label="意见建议" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-textarea
                    v-decorator="['nbyj', { initialValue: '已阅' }]"
                    :rows="4"
                    placeholder="请输入"
                ></a-textarea>
            </a-form-item>

            <div class="submit-bt">
                <a-button :loading="loading" type="primary" @click="handleSubmit" block> 提交 </a-button>
            </div>
        </a-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            form: this.$form.createForm(this),
            loading: false,
        }
    },
    methods: {
        handleSubmit() {
            if (this.loading) return
            this.loading = true
            this.form.validateFields((err, values) => {
                console.log('Received values of form: ', values)
                if (!err) {
                    this.$emit('submit', values)
                }
            })
        },
        handleRadioChage({ target: { value } }) {
            this.form.setFieldsValue({
                nbyj: value === '2' ? '驳回' : '已阅',
            })
        },
    },
}
</script>
